import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { JwtResponseDto, LoginDto } from '@portal/shared/utils';

import { TenantRegistryDto } from '../dto';

export class TenantManagerResource {
    private readonly api: HttpClient = inject(HttpClient);

    loginTenantAdmin(loginDto: LoginDto): Observable<JwtResponseDto> {
        return this.api.post<JwtResponseDto>('tenant/v1/manager/login', loginDto);
    }

    getTenantsInfo(): Observable<TenantRegistryDto[]> {
        return this.api.get<TenantRegistryDto[]>('tenant/v1/manager/info/all');
    }

    createNewTenant(tenantId: string): Observable<string> {
        return this.api.post<string>(`tenant/v1/manager/${tenantId}`, null);
    }
}
