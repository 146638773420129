import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { WEB_STORAGE } from '@common/utils';

import { AuthManager, JwtResponseDto, WebStorageService } from '@portal/shared/utils';

@Injectable({ providedIn: 'root' })
export class TenantNexusAuthService implements AuthManager {
    readonly #tokenKey: string = 'authTenantNexusToken';

    readonly #localeStorageService = inject(WebStorageService);
    readonly #storage = inject(WEB_STORAGE);

    readonly #tokenSource = new BehaviorSubject<JwtResponseDto | null>(this.#storage.getItem<JwtResponseDto>(this.#tokenKey));

    constructor() {
        this.#tokenSource.pipe(takeUntilDestroyed()).subscribe((token: JwtResponseDto | null) => {
            if (token === null) {
                this.#storage.removeItem(this.#tokenKey);
            } else {
                this.#storage.setItem<JwtResponseDto | null>(this.#tokenKey, token);
            }
        });
    }

    hasJwt(): boolean {
        return this.#tokenSource.value !== null;
    }

    getJwt(): JwtResponseDto | null {
        return this.#tokenSource.value;
    }

    setJwt(jwt: JwtResponseDto): void {
        this.#tokenSource.next(jwt);
    }

    initializeJwt(): Observable<JwtResponseDto | null> {
        return this.#localeStorageService.receive<JwtResponseDto | null>(this.#tokenKey);
    }

    isAuthenticated(): Observable<boolean> {
        return this.#tokenSource.pipe(map((jwt: JwtResponseDto | null) => jwt !== null));
    }

    resetJwt(): void {
        this.#tokenSource.next(null);
    }
}
